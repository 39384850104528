<template>
  
    
        <div class="has-text-centered">
          
          <h1 class="title is-size-3-desktop is-size-4-mobile ">{{ event.name }}</h1>
          <p>Please choose from the options below....</p>
          <div  style="max-width: 400px; margin: auto;">
        
          
            <b-select v-model="subevent" expanded rounded class="mt-3" style="max-width: 400px;">
            <option value="-" disabled>Discipline</option>
            <option v-for="edetails, sub in event.subevents" :key="sub" :value="sub">{{ edetails._title }}</option>
            </b-select>
          

          <br />
          

          
            <b-select :disabled="subevent=='-'" v-model="subsubevent" expanded rounded class="mt-3" style="max-width: 400px;">
            <option value="-" disabled>Variant</option>
            <template v-if="subevent!='-'">
            <option v-for="sedetails, ssub in event.subevents[subevent].sub_events" :key="ssub" :value="ssub">{{ sedetails._title }}</option>
            </template>
            </b-select>
          

        </div>
</div>
  

   
 
</template>
<script>

import { mapState } from 'vuex';

export default {
  name: "App",
  components: {
    
  },
  data() {
    return {

      subevent: "-",
      subsubevent: "-",



      current: 1,
      selectedColor: "beige",
      selectedSize: undefined,
      steps: [
        "Choose Event",
        ""
      ],

      selected: false,
     
      isOpenNotification: false,
      openTab: 1,
    };
  },
  computed: {
    ...mapState([
            'event',
        ])
  },
  methods: {
    addToCart() {
      this.isOpenNotification = true;
      setTimeout(() => {
        this.isOpenNotification = false;
      }, 3000);
    },
    selectColor(colorIndex) {
      this.product.colors.map((el, i) => {
        if (colorIndex === i) {
          el.selected = true;
          this.selectedColor = el.name;
        } else {
          el.selected = false;
        }
      });
    },
    changeTab(tabNumber) {
      document
        .getElementById("tabs")
        .scrollIntoView({ behavior: "smooth", block: "end" });
      this.openTab = tabNumber;
    },
  },
  mounted() {
    this.$emit("buttonsAllowed", { nextButton: true, prevButton: true})
  },
  watch: {
    subevent() {
      this.subsubevent="-";
      console.log(this.event.subevents[this.subevent])
      let ss = Object.keys(this.event.subevents[this.subevent].sub_events)
      console.log(ss)
      if (ss.length == 1) {
        this.subsubevent = ss[0]
      }
      else {
        this.$emit("buttonsAllowed", { nextButton: true, prevButton: true})
      }
    },
    subsubevent() {
      if (this.subsubevent!=="-" && this.subevent!='-') {
        this.$store.commit('setEvent', { sub: this.subevent, subsub: this.subsubevent})
        this.$emit("buttonsAllowed", { nextButton: false, prevButton: true})
        return;
      }
      
    }
  },
};
</script>
