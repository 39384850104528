<template>

<!--  -->
  <div id="default" class="mt-2 is-flex is-flex-direction-column has-text-centered" style="min-height: 100vh;">


  <vue-hcaptcha sitekey="834f42bb-0459-448b-b6a1-3a4afa981fc9" theme="dark"></vue-hcaptcha>
    

        <b-steps class="is-flex-grow-5"
            v-model="activeStep"
            :animated="true"
            :rounded="true"
            :has-navigation="false"
            icon-prev="chevron-left"
            icon-next="chevron-right"
            label-position="bottom"
            mobile-mode="minimalist">
            <b-step-item step="1" label="Event" :clickable="false">
                <EventPage @buttonsAllowed="changeButtonStates" ></EventPage>
            </b-step-item>

            <b-step-item step="2" label="Competitor" :clickable="false">
            <div class="has-text-centered">
          
          <h1 class="title is-size-3-desktop is-size-4-mobile ">{{ event.name }}</h1>
          <p>Please choose from the options below....</p>
                </div>
            </b-step-item>

            <b-step-item step="3" label="Options" :clickable="false">
                <h1 class="title has-text-centered">Social</h1>
                Lorem ipsum dolor sit amet.
            </b-step-item>

            <b-step-item step="4"  label="Summary" :clickable="false" disabled>
                <h1 class="title has-text-centered">Finish</h1>
                Lorem ipsum dolor sit amet.
            </b-step-item>

            <b-step-item step="4"  label="Pay" :clickable="false" disabled>
                <h1 class="title has-text-centered">Finish</h1>
                Lorem ipsum dolor sit amet.
            </b-step-item>

            <b-step-item step="4"  label="Entered!" :clickable="false" disabled>
                <h1 class="title has-text-centered">Finish</h1>
                Lorem ipsum dolor sit amet.
            </b-step-item>

            
        </b-steps>
        <template>
                <div class="has-text-centered">
                <b-button
                    outlined
                    type="is-danger"
                    icon-pack="fas"
                    icon-left="backward"
                    :disabled="previousBtn_disabled"
                    @click.prevent="backStep">
                    Previous
                </b-button>
                <b-button
                    outlined
                    type="is-success"
                    icon-pack="fas"
                    icon-right="forward"
                    :disabled="nextBtn_disabled"
                    @click.prevent="nextStep">
                    Next
                </b-button>
                </div>
            </template>
        <footer class="container is-flex">
          
            <div class="content has-text-centered">
              <p v-if="currentUserData">Logged in as {{ currentUserData.given_name }} {{ currentUserData.family_name }} </p>
              <b-button v-else @click="signin()">
        Sign In
    </b-button>

    
           
          </div>
        </footer>
   


  
    

  </div>
</template>
<script>

import { Auth,  } from "aws-amplify";
import EventPage from "./pages/Event.vue"
import { mapState } from 'vuex';
import VueHcaptcha from '@hcaptcha/vue-hcaptcha';
//import CompetitorPage from "./pages/Competitor.vue"
export default {
  name: "Default",
  components: {
  EventPage,
  VueHcaptcha,
  //  CompetitorPage,   
    //Auth,
    //Hub
  },
  data() {
    return {
       activeStep: 0,
       nextBtn_disabled: true,
       previousBtn_disabled: true,
    };
  },
  computed: {
    ...mapState([
            'event',
            'currentUserData'
        ])
  },
  methods: {

    changeButtonStates(value) {
      this.nextBtn_disabled = value.nextButton;
      this.previousBtn_disabled = this.activeStep || value.prevButton;

    },
    nextStep() {
      this.activeStep += 1;
    },
    backStep() {
      this.activeStep -=1;
    },
    signin() {
      Auth.federatedSignIn()
    },
    
    },
    mounted() {
      
      this.$Amplify.Auth.currentSession().then(res=>{

      this.$store.commit('userData', res.getIdToken().payload);

      
    }).catch(e => {
      console.log(e)
    })
    }
  
};
</script>

<style>
html {
  background: #000;
}

body {
  color: whitesmoke;
}

.b-steps .steps .step-items .step-item.is-active .step-marker {
  border-color:#3bd857;
  color: #3bd857;
  background-color: black;
}

.b-steps .steps .step-items .step-item::before, .b-steps .steps .step-items .step-item::after {
  background: linear-gradient(to left, #3bd857 50%, #3bd857 50%);
}

.b-steps .steps .step-items .step-item .step-link {
   color: #3bd857;
}

.title {
  color: #3bd857;
}



</style>
