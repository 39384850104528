import Vue from 'vue'
import Vuex from 'vuex'

import Amplify, * as AmplifyModules from 'aws-amplify'
import { AmplifyPlugin } from 'aws-amplify-vue'

import Buefy from 'buefy'
import 'buefy/dist/buefy.css'

Vue.use(Buefy, {
  defaultIconPack: 'fas',
  // ...
})

import App from './App.vue'

import store from './store';



Vue.use(AmplifyPlugin, AmplifyModules)
Vue.use(Vuex)

Vue.config.productionTip = false

Amplify.configure({
  Auth: {
    region: 'us-west-2',
    userPoolId: 'eu-west-2_2xz1mNtET',
    userPoolWebClientId: '65e7805bh9ul83us0ln359uo93',
    oauth: {
      domain: 'auth.challenging.events',
      scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin',],
      redirectSignIn: 'https://sqr.devel:8085/',
      redirectSignOut: 'https://sqr.devel:8085/',
      responseType: 'code'
    }
  },
});

AmplifyModules.Hub.listen('auth', (data) => {
  switch (data.payload.event) {
    case 'signIn':
        console.log('user signed in');
        console.log(data);
        console.log(
        AmplifyModules.Auth.currentUserInfo()
        );
        break;
    case 'signUp':
        console.log('user signed up');
        break;
    case 'signOut':
        console.log('user signed out');
        break;
    case 'signIn_failure':
        console.log('user sign in failed');
        break;
    case 'configured':
        console.log('the Auth module is configured');
  }
});




new Vue({
  store,
  render: h => h(App),
}).$mount('#app')
