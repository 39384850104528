import Vuex from 'vuex'
import Vue from 'vue'
import { format } from 'date-fns' 




Vue.use(Vuex)

const store = new Vuex.Store({

  

  state: {
    current: 1,
    selected_subevent: undefined,
    selected_subsubevent: undefined,
    reservation: undefined,
    
    event: {
      name: "The ORIGINAL Swanage Triathlon Festival",
      description:
        "Find stunning women cocktail and party dresses. Stand out in lace and metallic cocktail dresses and party dresses from all your favorite brands.",
      subevents: {
          "triathlon": {
              "_title": "Triathlon",
              "sub_events": {
                  "sprint_tri": {
                      "price": 52.5,
                      "_title": "Sprint"
                  },
                  "standard_tri": {
                      "price": 72.5,
                      "_title": "Standard"
                  },
                  "middle_tri": {
                      "price": 149.25,
                      "_title": "Middle"
                  },
                  "standard_tri_relay": {
                      "price": 83.25,
                      "_title": "Standard Relay"
                  },
                  "middle_tri_relay": {
                      "price": 150,
                      "_title": "Middle Relay"
                  }
              }
          },
          "aquathlon": {
              "_title": "Aquathlon",
              "sub_events": {
                  "sprint": {
                      "price": 30.75,
                      "_title": "Sprint"
                  }
              }
          },
          "longswim": {
              "_title": "Long Swim",
              "sub_events": {
                  "2_5k": {
                      "price": 24.25,
                      "_title": "2.5KM"
                  },
                  "5k": {
                      "price": 29.5,
                      "_title": "5KM"
                  }
              }
          },
          "aquabike": {
              "_title": "Aquabike",
              "sub_events": {
                  "aquabike": {
                      "price": 113.75,
                      "_title": "Middle Distance Aquabike"
                  }
              }
          }
      }
    },
      
    inPortal: false,
      lastInteraction: null,
      timeOfDay: "Day",
      currentDate: format(new Date(), "cccc, do LLL yyyy"),
      currentDateObj: new Date(),
      currentTime: format(new Date(), "pp"),
      curentHM: format(new Date(), "HH:mm"),
      
      currentDomain: null,
      currentTimeTable: null,
      currentISODate: null,
      currentUserData: undefined,

    },
    mutations: {

     
        setEvent(state, p) {
          state.selected_subevent = p.sub;
          state.selected_subsubevent = p.subsub;
          state.reservation = undefined;
        },
     

      userData (state, userData ) {
        state.currentUserData = userData;
        
      },

      

      
      showNav (state) {
        //console.log("showing nav....")
        state.shownav = true;
      },
      hideNav (state) {
        state.shownav = false;
      },
      interaction(state) {
        state.lastInteraction = Date.now();
      },
      portalStart(state) {
        state.inPortal = true;
        state.lastInteraction = Date.now();
        state.shownav = false;
      },
      updateTime(state) {
        state.currentDateObj = new Date()

        var h = state.currentDateObj.getHours(); // 0 - 23
      
        if(h > 11){
            if (h>16) {
              state.timeOfDay="Evening"
            }
            else {
              state.timeOfDay="Afternoon"
            }
        }
        else {
          state.timeOfDay="Morning"
        }

        state.currentDate = format(state.currentDateObj, "cccc, do LLL yyyy")
        state.currentTime = format(state.currentDateObj, "pp")
        state.curentHM= format(state.currentDateObj, "HH:mm")
        state.currentISODate = state.currentDateObj.toISOString().substring(0, 10)

       
        
      }


    },

  
  })

  export default store